<template>
    <div>
        <b-row v-if="user.user_rutbe == 1" class="m-0">
            <b-col cols="12" md="6" class="p-0 d-none">
                <b-button :style="{padding: padding}" to="/firmaForm" class="p-5 button_font" block squared size="lg">Toplama Noktası Oluştur</b-button>
            </b-col>
            <b-col cols="12" md="6" class="p-0">
                <b-button :style="{padding: padding}" to="/kullanicilar" class="p-5 button_font" block squared size="lg" variant="danger">Toplayıcı Ekle</b-button>
            </b-col>
            <b-col cols="12" md="6" class="p-0 d-none">
                <b-button :style="{padding: padding}" to="/bel_takip_list" class="p-5 button_font" block squared size="lg" variant="success">Belediye Şikayet Takip</b-button>
            </b-col>
            <b-col cols="12" md="6" class="p-0 d-none">
                <b-button :style="{padding: padding}" to="/firmalar" class="p-5 button_font" block squared size="lg" variant="primary">Toplama Noktaları</b-button>
            </b-col>
            <b-col cols="12" md="6" class="p-0">
                <b-button :style="{padding: padding}" to="/gmap" class="p-5 button_font" block squared size="lg">Konteyner Haritası</b-button>
            </b-col>
            <b-col cols="12" md="6" class="p-0">
                <b-button :style="{padding: padding}" to="/bolgeler" class="p-5 button_font" block squared size="lg" variant="warning">Bölge Yönetimi</b-button>
            </b-col>
            <b-col cols="12" md="6" class="p-0">
                <b-button :style="{padding: padding}" to="/rota" class="p-5 button_font" block squared size="lg" variant="primary">Rota Hazırla</b-button>
            </b-col>
            <b-col cols="12" md="12" class="p-0 d-none">
                <b-button :style="{padding: padding}" to="/rapor" class="p-5 button_font" block squared size="lg" variant="info">Rapor</b-button>
            </b-col>
        </b-row>
        <!-- <b-row v-if="user.user_rutbe == 1" class="m-0">
            <b-col cols="12" md="12" class="p-0">
                <b-button to="/rapor" :style="{padding: padding}" block squared size="lg" class="p-5 button_font">Rapor</b-button>    
            </b-col>
        </b-row> -->

        <b-row v-if="user.user_rutbe == 2" class="m-0">
            <b-col cols="12" md="6" class="p-0 d-none">
                <b-button to="/bel_sayfa_form" :style="{padding: padding}" block squared size="lg" class="p-5 button_font">Talep Ekle</b-button>
            </b-col>
            <b-col cols="12" md="6" class="p-0 d-none">
                <b-button to="/bel_sayfa" :style="{padding: padding}" block squared size="lg" class="p-5 button_font" variant="success">Talep Takip</b-button>
            </b-col>
        </b-row>
        <b-row v-if="user.user_rutbe == 2" class="m-0">
            <b-col cols="12" md="12" class="p-0">
                <b-button :style="{padding: padding}" to="/map" class="p-5 button_font" block squared size="lg" variant="primary">Toplama Noktaları</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    data(){
        return {
            windowHeight: window.innerHeight,
        }
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', () => {
                this.windowHeight = window.innerHeight
            });
        })
    },

    computed: {
        row(){
            return this.user.user_rutbe == 1 ? 4 : 2;
        },
        height(){
            return this.user.user_rutbe == 1 ? 110 : 80;
        },
        padding(){
            var deger =  (this.windowHeight - this.height) / this.row;
            return deger + 'px!important';
        },

        user(){
            return this.$store.state.user;
        },
    }
}
</script>

<style scoped>
.button_font{
    font-weight: 700;
    text-transform: uppercase;
}
</style>